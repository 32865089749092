<template>
  <div class="col-md-12">
    <div class="row">
      <div class="col-sm-12" style="padding-left:0px; border: 1px solid #ddd; padding: 10px; ">
        <ul class="nav nav-tabs">
          <li @click="tabClickEvent('CouponCenter')" class="active">
            <a data-toggle="tab" href="#couponCenter">Coupon Center</a>
          </li>
          <li @click="tabClickEvent('UnusedCoupons')">
            <a data-toggle="tab" href="#unusedCoupons">Unused Coupons</a>
          </li>
          <li @click="tabClickEvent('UsedCoupons')">
            <a data-toggle="tab" href="#usedCoupons">Used Coupons</a>
          </li>
          <li @click="tabClickEvent('ExpiredCoupons')">
            <a data-toggle="tab" href="#expiredCoupons">Expired Coupons</a>
          </li>
        </ul>
        <div class="tab-content" style="display: flex; flex: 1;">
          <div id="couponCenter" style="width: 100%;" class="classWell tab-pane fade in active">
            <div class="row">
              <div class="col-md-4 filter-block">
                Sort by
                <div>
                  <select
                    v-model="selectedFilterStatus"
                    class="form-control"
                    @change="haddleChange(selectedFilterStatus)"
                  >
                    <option
                      v-for="(item, index) in couponsFilterBy"
                      :key="index"
                      :value="item.couponsFilterId"
                    >{{item.text}}</option>
                  </select>
                </div>
              </div>
            </div>
            <br />

            <div class="row" v-if="couponAll.length !== 0">
              <div
                class="col-lg-3 col-md-4 col-sm-4 col-xs-12 mb-2"
                v-for="(obj, index) in couponAll"
                :key="index"
              >
                <coupon :couponObj="obj" :couponType="1" />
              </div>
            </div>
            <div class="row" v-if="couponAll.length == 0">
              <div class="col-md-12 mb-2">No record found</div>
            </div>
          </div>
          <div id="unusedCoupons" class="classWell tab-pane fade" style="width: 100%;">
            <div class="row" v-if="unusedCoupons.length !== 0">
              <div
                class="col-lg-3 col-md-4 col-sm-4 col-xs-12 mb-2"
                v-for="(obj, index) in unusedCoupons"
                :key="index"
              >
                <coupon :couponObj="obj" :couponType="2" />
              </div>
            </div>
            <div class="row" v-if="unusedCoupons.length == 0">
              <div class="col-md-12 mb-2">No record found</div>
            </div>
          </div>
          <div id="usedCoupons" class="classWell tab-pane fade" style="width: 100%;">
            <div class="row" v-if="usedCoupons.length !== 0">
              <div
                class="col-lg-3 col-md-4 col-sm-4 col-xs-12 mb-2"
                v-for="(obj, index) in usedCoupons"
                :key="index"
              >
                <coupon :couponObj="obj" :couponType="3" />
              </div>
            </div>
            <div class="row" v-if="usedCoupons.length == 0">
              <div class="col-md-12 mb-2">No record found</div>
            </div>
          </div>
          <div id="expiredCoupons" class="classWell tab-pane fade" style="width: 100%;">
            <div class="row" v-if="expiredCoupons.length !== 0">
              <div
                class="col-lg-3 col-md-4 col-sm-4 col-xs-12 mb-2"
                v-for="(obj, index) in expiredCoupons"
                :key="index"
              >
                <coupon :couponObj="obj" :couponType="3" />
              </div>
            </div>
            <div class="row" v-if="expiredCoupons.length == 0">
              <div class="col-md-12 mb-2">No record found</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import APICoupon from "@/mixins/buyer/APICoupon";
import Coupon from "@/components/buyer/Coupon/Coupon.vue";
export default {
  mixins: [APICoupon],
  components: {
    coupon: Coupon
  },
  data() {
    return {
      selectedFilterStatus: "-1",
      couponsFilterBy: [
        { couponsFilterId: "-1", text: "All" },
        { couponsFilterId: "1", text: "Catagory" },
        { couponsFilterId: "2", text: "Brand" },
        { couponsFilterId: "3", text: "Product" },
        { couponsFilterId: "4", text: "Store" }
      ],
      couponAll: [],
      unusedCoupons: [],
      usedCoupons: [],
      expiredCoupons: []
    };
  },
  created() {
    this.tabClickEvent("CouponCenter");
  },
  methods: {
    tabClickEvent: async function(str) {
      try {
        if (str === "CouponCenter") {
          this.haddleGetCouponAll();
        } else if (str === "UnusedCoupons") {
          this.haddleGetUnusedCoupons();
        } else if (str === "UsedCoupons") {
          this.haddleGetUsedCoupons();
        } else if (str === "ExpiredCoupons") {
          this.haddleGetExpiredCoupons();
        }
      } catch (error) {
        throw error;
      }
    },
    haddleChange: function(val) {
      if (val === "-1") {
        this.haddleGetCouponAll();
      } else {
        this.haddleGetCouponByFilter(val);
      }
    },
    haddleGetCouponAll: async function() {
      try {
        let res = await this.getCouponAll();
        this.couponAll = [];
        this.couponAll = res;
      } catch (error) {
        throw error;
      }
    },
    haddleGetCouponByFilter: async function(params) {
      try {
        let res = await this.getCouponByFilter(params);
        this.couponAll = [];
        this.couponAll = res;
      } catch (error) {
        throw error;
      }
    },
    haddleGetUnusedCoupons: async function() {
      try {
        let res = await this.getUnusedCoupons();
        this.unusedCoupons = [];
        this.unusedCoupons = res;
      } catch (error) {
        throw error;
      }
    },
    haddleGetUsedCoupons: async function() {
      try {
        let res = await this.getUsedCoupons();
        this.usedCoupons = [];
        this.usedCoupons = res;
      } catch (error) {
        throw error;
      }
    },
    haddleGetExpiredCoupons: async function() {
      try {
        let res = await this.getExpiredCoupons();
        this.expiredCoupons = [];
        this.expiredCoupons = res;
      } catch (error) {
        throw error;
      }
    }
  }
};
</script>