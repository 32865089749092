<template>
  <div>
    <div
      class="row"
      id="coupenDIv"
      style=" border: 2px dashed white; margin: 5px;" 
      :style="couponType === 1 || couponType === 2 ? 'background-color: #ffdc38;' : 'background-color: lightgrey;'"
    >
    <!-- ffdd57 -->
      <!-- {{ couponObj }} -->
      <!-- {{ couponType }}
    {{ couponObj.coupon_type }}
      {{ couponObj.used }}-->
      <!-- coupon_type 1 - one 2- multi -->
      <div class="col-12 presentage" :style="couponType === 1 || couponType === 2  ? 'color:#f86c00;':'color:#747474'" v-if="couponObj.amount_type === 1">{{ couponObj.amount }}%</div>
      <div class="col-12 presentage" :style="couponType === 1 || couponType === 2  ? 'color:#3a3a3a;':'color:#747474'" v-if="couponObj.amount_type === 2">Rs.{{ couponObj.amount }}</div>
      <div class="col-12 expiresIn">Expires on : {{ couponObj.expire_date !== null ? couponObj.expire_date.split(/T/)[0]:""}}
         <!-- {{  couponObj.expire_date.split(/T/)[1].split(/:00/)[0]}} -->
         <!-- {{  couponObj.expire_date.split(/T/)[1].split(/:00\+05:30/)[0]}} -->
         <!-- {{ couponObj.expire_date !== null ? couponObj.expire_date.split(/T/)[1].split('+00')[0].split(/:00\+05:30/)[0]:""}} -->

         </div>
      <hr
        :style="couponType === 1 || couponType === 2 ? 'border-top: 1px solid rgba(255, 0, 0, 0.1);' : 'border-top: 1px solid white;'"
      />
      <div class="col-12 expiresIn" :style="couponType === 1 || couponType === 2  ? 'color:#5dc655;':'color:#747474'" style="font-weight: 600;">{{ couponObj.coupon_name}}</div>
      
      <!-- applicables -->
      <div v-if="couponObj.applicables.toString().length > 0" class="col-12" style="text-align: -webkit-left;margin-left: 25px; margin-top: -18px; height: 20px;">Applicable for:</div> 
      
      <!-- <div id="coupenDetail">
        <div class="row" v-for="(obj, index) in couponObj.applicables" :key="index">
        <div class="col-12" style="text-align: -webkit-left;margin-left: 50px;">{{ obj }}</div>
        </div>
      </div>       -->

      <div class="col-12" style="text-align: center;margin: 25px; margin-top: 0px;">
      <!-- {{ couponObj.applicables }} -->
      <div>
      <div class="col-12 expiresIn-cType" v-if="couponObj.coupon_type && couponObj.coupon_type === 1">One Time use</div>
      <div class="col-12 expiresIn-cType" v-if="couponObj.coupon_type && couponObj.coupon_type === 2">Multiple use</div>
        
        <button
          v-if="couponType === 1 && couponObj.used === false"
          class="feedbackButton modal-trigger"
          @click="haddleGetIt(couponObj)"
        >Use it</button>
        <button
          v-if="couponType === 1 && couponObj.used === true"
          class="feedbackButton modal-trigger"
          @click="haddleUseIt(couponObj)"
        >Use it</button>
        <button
          v-if="couponType === 2 && couponObj.used === false"
          class="feedbackButton modal-trigger"
          @click="haddleGetIt(couponObj)"
        >Use it</button>
        <button
          v-if="couponType === 2 && couponObj.used === true"
          class="feedbackButton modal-trigger"
          @click="haddleUseIt(couponObj)"
        >Use it</button>
        </div>
        <!-- <button v-if="couponType === 3 && couponObj.used === false" class="feedbackButton modal-trigger" @click="haddleGetIt(couponObj)">Get it</button> -->
        <!-- <button v-if="couponType === 3 && couponObj.used === true" class="feedbackButton modal-trigger" @click="haddleUseIt(couponObj)">Use it</button> -->
      </div>
    </div>
  </div>
</template>
<script>
import APICoupon from "@/mixins/buyer/APICoupon";
export default {
  props: {
    couponObj: {
      type: Object,
      default: null
    },
    couponType: {
      type: Number,
      default: null
    }
  },
  mixins: [APICoupon],
  data() {
    return {};
  },
  methods: {
    haddleGetIt: async function(obj) {
      try {
        let postObj = {};
        postObj._id = obj._id;
        postObj.qr_coupon = false;

        let response = await this.postCouponUsed(postObj);

        this.$swal.fire({
          position: "center",
          type: "success",
          title: "Successfully coupon used",
          showConfirmButton: false,
          timer: 1500
        });

        if (response) {
          this.couponObj.used = true;
          this.haddleUseIt(obj);
        }
      } catch (error) {
        this.$swal.fire({
          position: "center",
          type: "error",
          title: error.data.message,
          showConfirmButton: false,
          timer: 1500
        });
      }
    },
    haddleUseIt: async function(obj) {
      // try {
      this.$router
        .push({
          name: "Product List Coupon",
          query: { id: obj._id, priceValue: obj.amount, amountType:obj.amount_type}
        })
        .catch(err => {});
      //   let response = await this.getCouponProducts(obj._id);
      //   console.log("used coupon");
      //   console.log(response);
      // } catch (error) {
      //   this.$swal.fire({
      //       position: 'center',
      //       type: 'error',
      //       title: error.data.message,
      //       showConfirmButton: false,
      //       timer: 1500
      //   })
      // }
    }
  }
};
</script>
<style scoped>
#coupenDIv {
  height: 260px;
}

#coupenDetail{
  height: 23%;
}
.presentage {
  font-family: Poppins;
  font-size: 32px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.48;
  letter-spacing: normal;
  text-align: center;
  color: #3a3a3a;
  margin-top: 25px;
}
.expiresIn {
  min-height: 36px;
  font-family: Poppins;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #5b5b5b;
}
.expiresIn-cType {
  font-family: Poppins;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #5b5b5b;
  margin: 10px;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(255, 0, 0, 0.1);
}

.improveArticleButton,
.feedbackButton {
  display: inline-block;
  background-color: white;
  border-color: #ff5f00;
  color: #e86709;
  border: 1px solid #ff5f00;
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 20px;
  width: 104px;
  height: 36px;
  margin-bottom: 10px;
  outline: none !important;
}
</style>
